import { CONSTANTS } from '../api/constants';
let initState = {
    batery: 0,
    voltage: 0,
    current: 0,
    soc: 0,
    timeLeftCharge: '-'
}

export function charging(state = initState, action) {
    // console.log(state)
    switch (action.type) {
        case CONSTANTS.CHARGING.BMS_DATA:
            return {
                ...action.data
            }

        case CONSTANTS.CLEAR:
            return initState
        default:
            return state
    }
}