import React from 'react';
import PropTypes from 'prop-types';
import { PaginationItem, PaginationLink } from 'reactstrap';

const PageNext = ({ pagination, sheetPerPage, dispatch, types, onSelected }) => {
    let pageMaxSection = Math.ceil(pagination.totalPage / sheetPerPage);
    let pageCurrentSection = Math.ceil(pagination.currentPage / sheetPerPage);

    if (pageMaxSection === pageCurrentSection) {
        return (
            <PaginationItem disabled>
                <PaginationLink next />
            </PaginationItem>
        );
    }
    return (
        <PaginationItem>
            <PaginationLink
                next
                onClick={() => {
                    if (dispatch && types)
                        dispatch({ type: types, data: pageCurrentSection * sheetPerPage + 1 })
                    else
                        onSelected(pageCurrentSection * sheetPerPage + 1)
                }}
            />
        </PaginationItem>
    );
};

PageNext.propTypes = {
    pagination: PropTypes.object,
    sheetPerPage: PropTypes.number,
    dispatch: PropTypes.func,
    types: PropTypes.string,
    onSelected: PropTypes.func
};

export default PageNext;
