import React from 'react';
import PropTypes from 'prop-types';
import { PaginationItem, PaginationLink } from 'reactstrap';

function PageLast({ pagination, sheetPerPage, dispatch, types, onSelected }) {
    if (pagination.currentPage === pagination.totalPage) {
        return (
            <PaginationItem disabled>
                <PaginationLink next />
            </PaginationItem>
        );
    }
    return (
        <PaginationItem>
            <PaginationLink
                next
                onClick={() => {
                    if (dispatch && types)
                        dispatch({ type: types, data: pagination.totalPage });
                    else
                        onSelected(pagination.totalPage)
                }}
            />
        </PaginationItem>
    );
}

PageLast.propTypes = {
    pagination: PropTypes.object,
    sheetPerPage: PropTypes.number,
    dispatch: PropTypes.func,
    types: PropTypes.string,
    onSelected: PropTypes.func
};

export default PageLast;

