import React from 'react';
import PropTypes from 'prop-types';
import { PaginationItem, PaginationLink } from 'reactstrap';

function PageItems({ pagination, sheetPerPage, dispatch, types, onSelected }) {

    let pageCurrentSection = Math.ceil(pagination.currentPage / sheetPerPage);
    let pageCurrentMax = sheetPerPage;
    if (pagination.totalPage < sheetPerPage) {
        pageCurrentMax = pagination.totalPage;
    } else {
        if (pageCurrentSection * sheetPerPage > pagination.totalPage) {
            pageCurrentMax = sheetPerPage - (pageCurrentSection * sheetPerPage - pagination.totalPage);
        }
    }
    let pageCount = Array.from(
        Array(pageCurrentMax),
        (_, x) => (pageCurrentSection - 1) * sheetPerPage + 1 + x
    );
    return pageCount.map(page => {
        let active = true;
        if (page !== pagination.currentPage) {
            active = false;
        }
        return (
            <PaginationItem key={page} active={active}>
                <PaginationLink
                    onClick={() => {
                        if (dispatch && types)
                            dispatch({type: types, data: page});
                        else
                            onSelected(page)
                    }}
                >
                    {page}
                </PaginationLink>
            </PaginationItem>
        );
    });
}

PageItems.propTypes = {
    pagination: PropTypes.object,
    sheetPerPage: PropTypes.number,
    dispatch: PropTypes.func,
    types: PropTypes.string,
    onSelected: PropTypes.func
};

export default PageItems;

