import { CONSTANTS } from '../api/constants';
let initState = {
  vls: {
    username: '',
    password: '',
    secret: ''
  },
  streamPath: [], // { appSerialId: "<>", path: "<>" }
  drones: [], //
  currentVideo: {
    appSerialId: ''
  }
}

export function drone(state = initState, action) {
  // console.log(state)
  switch (action.type) {
    case CONSTANTS.DRONE.VLS_INFO:
      return {
        ...state,
        vls: action.data
      }
    case CONSTANTS.DRONE.VLS_STREAM:
      return {
        ...state,
        streamPath: action.data
      }
    case CONSTANTS.DRONE.LISTS:
      return {
        ...state,
        drones: action.data
      }
    case CONSTANTS.DRONE.SET_CURRENT_VIDEO_FEED:
      return {
        ...state,
        currentVideo: { appSerialId: action.data }
      }

    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}