import { CONSTANTS } from '../api/constants';

let initState = {
  markers: [
    // {id:0, lat: 1.55945362431247,lng:103.63747168418352, label:"1"},
    // {id:1, lat: 1.5592319770908931, lng:103.63805461771857, label:"2"}
  ],
  mapCenter: {
    lat: 1.5594,
    lng: 103.6386
  },
  defaultAltitute: 10,
  totalDistance: 0,
  planner: [], // [{plannerId: <>,...}]
  currentPlan: { id: 0 },
  waypointSelected: {}, // waypoint: {lat:<>, lng:<>, alt:<>,seq:<>, id:<>}
}

export function planner(state = initState, action) {
  switch (action.type) {
    case CONSTANTS.PLANNER.COMMAND_REQUEST:
      return {
        request: true,
        data: [...state.data]
      };
    case CONSTANTS.PLANNER.COMMAND_SUCCESS:

      return {
        data: action.result
      };
    case CONSTANTS.PLANNER.COMMAND_FAILURE:
      return {
        message: action.message,
        data: [...state.data]
      };
    case CONSTANTS.PLANNER.MARKER_LIST:
      return {
        ...state,
        ...action.data
      };
    case CONSTANTS.PLANNER.UPDATE_MARKER:
      return {
        ...state,
        ...action.data
      };
    case CONSTANTS.PLANNER.UPDATE_ALT:
      console.log("check data->", action.data)
      state.markers[action.data.markerId].alt = action.data.newAlt;
      return {
        ...state
      };

    case CONSTANTS.PLANNER.UPDATE_CURRENT_PLAN:
      return {
        ...state,
        currentPlan: action.data
      }
    case CONSTANTS.PLANNER.LISTS:
      return {
        ...state,
        planner: action.data
      }
    case CONSTANTS.PLANNER.WAYPOINT_SELECTED:
      return {
        ...state,
        waypointSelected: action.data
      }
    case CONSTANTS.PLANNER.MAP_CENTER_VIEW:
      return {
        ...state,
        mapCenter: action.data
      }
    case CONSTANTS.PLANNER.UPDATE_ALTITUDE:
      return {
        ...state,
        defaultAltitute: action.data
      }

    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}