import React from 'react';

import { Pagination } from 'reactstrap';
import PageFirst from './PageFirst/PageFirst';
import PagePrev from './PagePrev/PagePrev';
import PageItems from './PageItems/PageItems';
import PageNext from './PageNext/PageNext';
import PageLast from './PageLast/PageLast';

const TablePagination = (prop) => {
    return (
        <div style={{ display: 'inline-block' }}>
            <Pagination size="sm" aria-label="Page navigation" className="table-pgn">
                <PageFirst {...prop} />
                <PagePrev {...prop} />
                <PageItems {...prop} />
                <PageNext {...prop} />
                <PageLast {...prop} />
            </Pagination>
        </div>
    )
}

export default TablePagination;
