import React from 'react';
import PropTypes from 'prop-types';
import { PaginationItem, PaginationLink } from 'reactstrap';

const PagePrev = ({ pagination, sheetPerPage, dispatch, types, onSelected }) => {
    let pageCurrentSection = Math.ceil(pagination.currentPage / sheetPerPage);
    if (pageCurrentSection === 1) {
        return (
            <PaginationItem disabled>
                <PaginationLink previous />
            </PaginationItem>
        );
    }
    return (
        <PaginationItem>
            <PaginationLink
                previous
                onClick={() => {
                    if (dispatch && types) {
                        dispatch({ type: types, data: (pageCurrentSection - 2) * sheetPerPage + sheetPerPage });
                    }
                    else {
                        onSelected((pageCurrentSection - 2) * sheetPerPage + sheetPerPage);
                    }
                }}
            />
        </PaginationItem>
    );
};

PagePrev.propTypes = {
    pagination: PropTypes.object,
    sheetPerPage: PropTypes.number,
    dispatch: PropTypes.func,
    types: PropTypes.string,
    onSelected: PropTypes.func
};

export default PagePrev;
