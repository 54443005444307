import { CONSTANTS } from '../api/constants';
let initState = {
  id: 0,
  speed: 2
}

export function controller(state = initState, action) {
  // console.log(state)
  switch (action.type) {
    case CONSTANTS.CONTROLLER.SET_SPEED:
      return {
        ...state,
        speed: action.data
      }
    case CONSTANTS.CONTROLLER.COMMAND_REQUEST:
      return {
        ...state,
        request: true,
        data: state.id
      };
    case CONSTANTS.CONTROLLER.COMMAND_SUCCESS:
      return {
        ...state,
        data: action.result
      };
    case CONSTANTS.CONTROLLER.COMMAND_FAILURE:
      return {
        ...state,
        message: action.message,
        data: action.result
      };
    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}