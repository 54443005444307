import { combineReducers } from 'redux';
import { dashboard } from './dashboard'
import { controller } from './controller';
import { planner } from './planner';
import { drone } from './drone'
import { histories } from './histories'
import { charging } from './charging'

const rootReducer = combineReducers({
  dashboard,
  controller,
  planner,
  drone,
  histories,
  charging
});

export default rootReducer;