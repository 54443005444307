var HOST_SERVER = window.location.hostname, API_ROUTE = '', MQTT_URL = '', VLS_URL = ''

let dev = true
if (typeof process.env.REACT_APP_MODE === 'undefined') {
    if (typeof process.env.NODE_ENV !== 'undefined') {
        if (process.env.NODE_ENV === 'production') {
            dev = false
        }
    }
} else {
    if (process.env.REACT_APP_MODE !== 'dev') {
        dev = false
    }
}
dev = false
if (dev) {
    API_ROUTE = 'http://' + HOST_SERVER + ':3026/api'
    MQTT_URL = 'ws://' + HOST_SERVER + ':4891'
    VLS_URL = 'http://' + HOST_SERVER + ':9000'
} else {
    HOST_SERVER = 'drones.utmsecurity.innates.my'
    API_ROUTE = 'http://' + HOST_SERVER + '/api'
    MQTT_URL = 'ws://' + HOST_SERVER + '/ws'
    // VLS_URL = 'http://' + HOST_SERVER + '/vls'
    // VLS_URL = 'http://' + window.location.hostname + '/live/cam01.flv'
    VLS_URL = 'http://' + HOST_SERVER + '/live/cam01.flv'
}

export const SERVER = {
    API: {
        Login: API_ROUTE + '/AppUsers/login',
        Logout: API_ROUTE + '/AppUsers/logout',
        ChangePassword: API_ROUTE + '/AppUsers/change-password',
        ResetPassword: API_ROUTE + '/AppUsers/reset/password',
        resetPasswordByAdmin: API_ROUTE + '/AppUsers/resetpasswordbyadmin',

        Current: {
            MAIN: API_ROUTE + '/Routes',
        },
        Command: {
            SubmitCommand: API_ROUTE + '/Commands/command',
            Drone: API_ROUTE + '/Commands/drones',
            ChargingStation: API_ROUTE + '/Commands/chargeStation',
        },
        Planner: {
            MAIN: API_ROUTE + '/Planners',
            RemoveLocation: API_ROUTE + '/Planners/remove/location',

            UpdateLocation: (id) => { return API_ROUTE + '/Planners/' + id },
            Remove: (id) => { return API_ROUTE + '/Planners/remove/' + id },
            Waypoint: (id) => { return API_ROUTE + '/Planners/' + id + '/waypoint' },
            RemoveWaypoint: (id, fk) => { return API_ROUTE + '/Planners/' + id + '/waypoint/' + fk },
            UpdateWaypoint: (id) => { return API_ROUTE + '/Waypoints/' + id }
        },
        Drone: {
            List: API_ROUTE + '/Drones',
            Register: API_ROUTE + '/Drones/register',
            GetVLS: API_ROUTE + '/Drones/getVLS',
        }
    },
    MQTT: {
        URL: MQTT_URL,
        User: 'drones_utm_dash',
        Pass: 'asdh@453bia&2sfeeYq3rs'
    },
    LiveStream: {
        VLS_URL: VLS_URL,
        Stream: VLS_URL + '/api/streams'
    }
}